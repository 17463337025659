<template>
  <div class="page">
    <el-breadcrumb separator="/" style="margin: 15px 0 25px;">
      <el-breadcrumb-item>
        用户
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        修改个人信息
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
<!--      <el-card class="box-card">-->
<!--        <div slot="header" class="clearfix">-->
<!--          <span class="title">登陆密码</span>-->
<!--        </div>-->
<!--        <el-form class="normal-form" label-width="100px" @submit.native.prevent>-->
<!--          <el-form-item label="新密码" :error="errorBags.collect('新密码')[0]">-->
<!--            <el-input-->
<!--              type="password"-->
<!--              v-model="addFields.pwd"-->
<!--              data-vv-name="新密码"-->
<!--              ref="新密码"-->
<!--              v-validate="'required|alpha_dash|max:50|min:8'"-->
<!--            ></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item>-->
<!--            <el-button type="primary" @click="changePassword">保存</el-button>-->
<!--            <el-button @click="goBack">返回</el-button>-->
<!--          </el-form-item>-->
<!--        </el-form>-->
<!--      </el-card>-->
      <el-card class="box-card" style="margin-top:20px">
        <div slot="header" class="clearfix">
          <span class="title">对外信息</span>
        </div>
        <el-form class="normal-form" label-width="100px" data-vv-scope="edit" size="small">
          <el-form-item label="昵称" required >
            <el-col :span="12">
              <el-input clearable
                v-model="wx_nick"
                name="昵称"
              ></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="微信二维码" required :error="errorBags.collect('edit.手机号')[0]">
            <ali-uploader :autoUpload="false" :uploadFolder="false" folderName="img"
            :showUploadBtn="false" :accept="imgAccept" selectButtonText="上传图片"
            :showPreview="true" uploadRef="out_information" ref="out_information"
              @getDir="getDir" @beforeUpload="beforeUpload" :previewImg="codeImg"
              @handleChange="handleChange" @handleFailed="handleFailed" @showImg="showCode"
              @getUploadUrl="getUploadUrlCode"
              >
            </ali-uploader>
            微信二维码大小至少为300px*300px
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="addOutInformation">保存</el-button>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card class="box-card" style="margin-top:20px">
        <div slot="header" class="clearfix">
          <span class="title">钉钉信息</span>
        </div>
        <el-form class="normal-form" label-width="100px" size="small">
          <el-form-item label="钉钉手机号" required>
            <el-col :span="10">
              <el-input clearable
                v-model="mobile"
              ></el-input>
            </el-col>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="bindDingding">确认绑定</el-button>
            <el-button type="danger" @click="unbindDingding">解除绑定</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import { userApi } from '@/api'
import gobackMixin from '@/mixins/goback'

export default {
  mixins: [gobackMixin],

  data: () => ({
    addFields: {
      pwd: ''
    },
    wx_nick: '',
    wx_thumb: '',
    wx_qr: '',
    headerImg: '',
    codeImg: '',
    isHeader: '',
    isCode: '',
    mobile: ''
  }),

  methods: {
    async bindDingding() {
      if (!/^\d{11}$/.test(this.mobile)) {
        this.$message.error('请输入正确的手机号')
        return
      }
      let loading = this.$loading()
      try {
        let data
        data = (await userApi.bindDingding({
          mobile: this.mobile
        })).data
        if (data.code === 0) {
          this.$message({
            message: '绑定钉钉成功',
            type: 'success'
          })
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    },

    async unbindDingding() {
      if (!/^\d{11}$/.test(this.mobile)) {
        this.$message.error('请输入正确的手机号')
        return
      }
      let loading = this.$loading()
      try {
        let data
        data = (await userApi.unBindDingding({
          user_id: this.$store.state.User.user.id,
          mobile: this.mobile
        })).data
        if (data.code === 0) {
          this.$message({
            message: '解绑钉钉成功',
            type: 'success'
          })
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    },

    changePassword() {
      this.$validator.validate().then(status => {
        if (status) {
          userApi.changeOwnPassword(this.addFields).then(res => {
            let data = res.data
            if (data.code === 0) {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.goBack()
            } else {
              this.$error(data)
            }
          })
        } else {
          this.$message({
            message: '请正确填写数据',
            type: 'error'
          })
        }
      })
    },

    // 保存对外信息
    addOutInformation() {
      if (this.wx_nick) {
        if (this.isHeader && this.isCode) {
          let params = { wx_nick: this.wx_nick, wx_thumb: this.wx_thumb, wx_qr: this.wx_qr }
          this.doAddOutInformation(params)
        } else {
          this.$message.error('请上传图片')
        }
      } else {
        this.$message.error('请填写昵称')
      }
    },

    async doAddOutInformation(params) {
      let loading = this.$loading()
      try {
        let data = (await userApi.saveUserExternalInformation(params)).data
        if (data.code === 0) {
          this.$message.success('保存成功')
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    },

    // 获取对外信息
    async getExternalInformation() {
      let loading = this.$loading()
      try {
        let data = (await userApi.getExternalInformation()).data
        if (data.code === 0) {
          this.wx_nick = data.data.wx_nick
          this.wx_thumb = data.data.wx_thumb
          this.wx_qr = data.data.wx_qr
          this.headerImg = data.data.thumb
          this.codeImg = data.data.qr
          this.mobile = data.data.ding_ding.mobile
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    },

    getUploadUrl(val) {
      this.wx_thumb = val
    },

    getUploadUrlCode(val) {
      this.wx_qr = val
    },

    showHeader(val) {
      this.isHeader = val
    },
    showCode(val) {
      this.isCode = val
    },

  },
  mounted () {
    this.thisImageFolder = '11'
    this.getExternalInformation()
  },
}
</script>
<style lang="less" scoped>
.box-card{
  .title{
    font-weight: 700;
    font-size: 20px;
  }
}
.preview-img{
  width: 140px !important;
  border: 1px solid black;
}
</style>
